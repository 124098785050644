<!--  -->
<template>
  <div class="forgotPwd-page">
    <div class="wrap">
      <div class="wrap_left">
        <h2>Welcome Back !</h2>
        <p>
          Welcome back to Classnovo. Please login before continue.
        </p>
        <div class="img">
          <img :src="require('@/assets/images/forget_pwd_bg.png')" alt="" />
        </div>
      </div>
      <div class="wrap_right">
        <div class="title">Reset Password</div>
        <p class="remark">
          A reset password link will be sent to the email address below.
        </p>
        <div class="form">
          <gg-input
            key="formForgotPwd"
            ref="formForgotPwd"
            v-model="forgotPwdValue"
            filedType="input"
            type="input"
            placeholder="Email Address"
            :error="errorObj"
          />

          <div class="bottom">
            <div class="cancel" @click="handleCancel">Cancel</div>
            <!-- <div
              class="submit"
              :class="{ invalid: invalid }"
              @click="handleLogin"
            >
              Send
            </div> -->
            <baseButton
              class="submit"
              type="primary"
              :disabled="isDisabled"
              @click="handleLogin"
              >Send</baseButton
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Ajax } from "@/common";
export default {
  data() {
    return {
      forgotPwdValue: "",
      pwdReg: /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/,
      errorObj: {
        show: false,
        message: "Invalid email address",
      },
    };
  },
  computed: {
    isDisabled() {
      if (this.errorObj.show) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    validate(ref) {
      this.errorObj.show = false;
      let isError = false;
      // 提交之前先将每个input初始化字段init,show设为false，要不无法触发默认错误提示
      this.$refs["formForgotPwd"].init = false;
      if (this.pwdReg && !this.pwdReg.test(this.forgotPwdValue)) {
        this.errorObj.show = true;
        isError = true;
      }
      return !isError;
    },
    handleCancel() {
      this.$router.go(-1);
    },
    handleLogin(e) {
      if (this.validate()) {
        Ajax.post("/usermanage/email/resetPasswdByEmail", {
          email: this.forgotPwdValue,
        })
          .then((res) => {
            if (res.code === "0000") {
              this.$router.push("/login");
            } else {
              this.errorObj = {
                show: true,
                message: res.message,
              };
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  created() {
    this.forgotPwdValue = this.$route.query.email;
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类

.forgotPwd-page {
  width: 1424px;
  height: 584px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url("~@/assets/images/login_bg.png") no-repeat 50% 100%;
  overflow: hidden;
  .wrap {
    display: flex;
    width: 800px;
    height: 560px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.16);
    border-radius: 16px;
    overflow: hidden;
    .wrap_left {
      flex: 1 1 50%;
      height: 100%;
      background: #255180;
      padding: 50px 32px;
      text-align: left;

      h2 {
        font-family: OpenSans-Bold;
        font-size: 32px;
        font-weight: 700;
        color: #fff;
        margin-bottom: 17px;
        line-height: 45px;
      }
      p {
        font-size: 16px;
        font-family: Roboto;
        font-weight: 500;
        color: #fff;
        line-height: 24px;
        margin-bottom: 58px;
      }
      .img {
        padding: 0 16px;
      }
    }
    .wrap_right {
      flex: 1 1 50%;
      padding: 50px 32px;
      text-align: left;
      background: #fff;
      .title {
        height: 43px;
        line-height: 43px;
        font-family: OpenSans-Bold;
        color: #202124;
        font-size: 32px;
        margin-bottom: 36px;
      }
      .remark {
        font-size: 14px;
        color: #202124;
        margin-bottom: 24px;
        height: 70px;
        line-height: 19px;
      }
      .form {
      }
      .bottom {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .cancel {
          color: #5f6368;
          cursor: pointer;
          font-family: OpenSans-Bold;
          margin-right: 19px;
        }
        .submit {
          width: 64px;
          background: #1a73e8;
          color: #fff;
          font-family: OpenSans-Bold;
          border-radius: 4px;
          text-align: center;
          // line-height: 32px;
          cursor: pointer;
          &.invalid {
            background: #b4b4b4;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
</style>